@tailwind base;
@tailwind components;
@tailwind utilities;
/* Gilroy Font Family */
@font-face {
    font-family: "Gilroy";
    font-display: swap;
    src: url("./fonts/Gilroy-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy-SemiBold.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* Proxima Nova Font Family */
@font-face {
    font-family: "Proxima Nova";
    font-display: swap;
    src: url("./fonts/ProximaNova-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./fonts/ProximaNova-Semibold.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./fonts/ProximaNova-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: "Proxima Nova", Arial, sans-serif;
}
h1,
h2,
h3 {
    font-family: "Gilroy", "Helvetica Neue", Helvetica, sans-serif;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
